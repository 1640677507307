import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Section from '../components/section'
import Listing from '../components/listing'
import InfoBox from '../components/info-box'
import StarRating from '../components/star-rating'
import SubscribeCta from '../components/subscribe-cta'

const IdeasListTemplate = ({ data }) => {
  const ideas = data.allMarkdownRemark.nodes
  const canonicalUrl = `${data.site.siteMetadata.siteUrl}/ideas/`

  const metaDesc = `Ideas on how to get creative and level up your s’mores ` +
    `experience.`

  const socialImage = 'https://www.smorescout.com/images/social/reviews-summary.png'
  const socialImageAlt = `s’mores ideas`

  return (
    <Layout>
      <Seo
        title="S’mores Ideas"
        description={metaDesc}
        canonicalUrl={canonicalUrl}
        meta={[
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: socialImage,
          },
          {
            property: 'og:image:alt',
            content: socialImageAlt,
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: socialImage,
          },
          {
            name: 'twitter:image:alt',
            content: socialImageAlt,
          },
        ]}
      />

      <Section className="pb-5">
        <Container>
          <Row className="justify-content-around">
            <Col span={12} lg={9} xl={12} className="mb-2">
              <Breadcrumb>
                <Breadcrumb.Item
                  href="/"
                  linkProps={{ className: "breadcrumb-link" }}
                >
                  Home
                </Breadcrumb.Item>
              </Breadcrumb>

              <h1>
                S’mores Ideas
              </h1>
            </Col>
          </Row>

          <Row className="justify-content-around">
            <Col xs={12} lg={9} xl={8}>
              <p className="lead mb-5">
                Ideas on how to get creative and level up your s’mores
                experience.
              </p>

              {ideas.map(idea => (
                <div key={idea.fields.slug} className="mb-4">
                  <Listing
                    slug={idea.fields.slug}
                    title={idea.frontmatter.title}
                    summary={idea.frontmatter.summary}
                    image={idea.frontmatter.images[0]}
                    theme="marshmallow"
                  />
                </div>
              ))}
            </Col>

            <Col as="aside" xs={12} lg={9} xl={4}>
              <InfoBox>
                <InfoBox.Body>
                  <h2 className="h4 mb-3">
                    We're just getting started!
                  </h2>

                  <div className="mb-3">
                    <StarRating rating={2} size="large" theme="dark" />
                  </div>
                  <p>
                    Hang tight, s’more ideas are coming.
                  </p>
                  <p>
                    Have an idea? Email me at
                    {' '}
                    <strong>
                      <a href="mailto:chris@smorescout.com">
                        tips@smorescout.com
                      </a>
                    </strong>
                  </p>
                </InfoBox.Body>
              </InfoBox>
            </Col>
          </Row>
        </Container>
      </Section>

      <SubscribeCta>
        <h2 className="mb-3">
          Get s’more ideas in your inbox
        </h2>
      </SubscribeCta>
    </Layout>
  )
}

export const query = graphql`
  query Ideas {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMarkdownRemark(
      filter: { fields: { content_type: { eq: "ideas" }}}
      sort: { fields: frontmatter___published_on, order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          summary
          images: image_gallery {
            thumb: file {
              childImageSharp {
                gatsbyImageData(width: 247, height: 247, transformOptions: {
                  cropFocus: CENTER
                })
              }
            }
            alt
          }
        }
      }
    }
  }
`

export default IdeasListTemplate
